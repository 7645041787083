export default {
  fileParse(file, type = 'base64') {
    return new Promise(resolve => {
      const fileRead = new FileReader()
      if (type === 'base64') {
        fileRead.readAsDataURL(file)
      } else if (type === 'buffer') {
        console.log('--------')
        console.log(file)
        fileRead.readAsArrayBuffer(file)
      }
      fileRead.onload = (ev) => {
        resolve(ev.target.result)
      }
    })
  }
}
