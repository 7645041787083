<template>
  <div class="login">
    <a-upload
      name="file"
      :multiple="true"
      :headers="headers"
      @change="test1"
    >
      <a-button>
        <a-icon type='upload' />Click to Upload
      </a-button>
    </a-upload>

    <botton @click="test2">按钮</botton>
    <!-- <iframe class="prism-player" :src="pdfUrl" width="100%" height="100%"></iframe> -->
  </div>
</template>

<script>
import test from '@/utils/demo/demo'
import SparkMD5 from 'spark-md5'
export default {
  filters: {
    btnText(btn) {
      return btn ? '继续' : '暂停'
    },
    totalText(total) {
      return total > 100 ? 100 : total
    }
  },
  data() {
    return {
      headers: {
        authorization: 'authorization-text',
        total: 0,
        video: null,
        btn: false,
        contrastarr: [] // 已经上传的分片
      }
    }
  },
  created() {
    // console.log(test.changeColor())
    // this.test1()
  },
  beforeDestroy() {
    this.trim.forEach(t => {
      clearTimeout(t)
    })
  },
  methods: {
    async changeFile(file) {
      console.log(file)
      // console.log(typeof file.file.originFileObj)
      if (!file) return
      file = file.file.originFileObj

      this.size = file.size
      // 解析为BUFFER数据
      // 我们会把文件切片处理：把一个文件分割成为好几个部分（固定数量/固定大小）
      // 每一个切片有自己的部分数据和自己的名字
      // HASH_1.mp4
      // HASH_2.mp4
      // ...
      const buffer = await test.fileParse(file, 'buffer')
      const spark = new SparkMD5.ArrayBuffer()
      let hash = null
      let suffix = null
      spark.append(buffer)
      hash = spark.end()
      suffix = /\.([0-9a-zA-Z]+)$/i.exec(file.name)[1]
      // 创建100个切片
      const partList = []
      const partsize = file.size / 10
      let cur = 0
      for (let i = 0; i < 10; i++) {
        const chunk = file.slice(cur, cur + partsize)
        chunk.name = `${hash}_${i}.${suffix}`
        const item = {
          chunk: chunk,
          filename: `${hash}_${i}.${suffix}`,
          sliceNum: i
        }
        cur += partsize
        partList.push(item)
      }
      this.partList = partList
      this.hash = hash
      this.sendRequest()
    },
    async sendRequest() {
      // console.log('----------')
      console.log(this.partList)
      console.log(this.size)
      const that = this
      // console.log(this.hash)
      // 根据100个切片创造100个请求（集合）
      const requestList = []
      this.partList.forEach((item, index) => {
        // 每一个函数都是发送一个切片的请求
        const fn = () => {
          const formData = new FormData()
          formData.append('mainModel', 'GD_AJPC_DDXC')
          formData.append('mainSize', that.size)
          formData.append('mainSuff', item.filename.split('.')[1])
          formData.append('spliceModel', 'GD_AJPC_DDXC')
          formData.append('spliceSize', item.chunk.size)
          formData.append('spliceNum', index)

          const files = new window.File([item.chunk], item.chunk.name)
          formData.append('meFile', files)
          // const formData = {
          //   mainModel: 'GD_AJPC_DDXC',
          //   mainSize: 12,
          //   mainSuff: item.filename.split('.')[1],
          //   spliceModel: 'nn',
          //   spliceSize: item.chunk.size,
          //   spliceNum: index,
          //   meFile: item.chunk
          // }

          return this.$http('file_largeFile_saveSpliceFile', formData).then(res => {
            if (res.code === 200) {
              this.total += 1
              // 传完的切片我们把它移除掉
              // this.partList.splice(index, 1)
            } else {
              this.abort = true
              this.$message.error(res.message)
            }
          })
        // that.axios
        //     .post('/largeFile/saveSpliceFile', formData, {
        //       headers: { 'Content-Type': 'multipart/form-data' }
        //     })
        //     .then(result => {
        //       console.log('-----99')
        //       result = result.data
        //       if (result.code === 0) {
        //         this.total += 1
        //         // 传完的切片我们把它移除掉
        //         this.partList.splice(index, 1)
        //       }
        //     })
        }
        requestList.push(fn)
      })

      // 传递：并行(ajax.abort())/串行(基于标志控制不发送)
      let i = 0
      const complete = async() => {
        this.$message.error('都传完了')

        return this.$http('post_largeFile_mergeFile', { mainModel: 'GD_AJPC_DDXC', mainSize: this.size }, true).then(res => {
          if (res.code === 200) {
            this.total += 1
            // 传完的切片我们把它移除掉
            // this.partList.splice(index, 1)
          } else {
            this.abort = true
            this.$message.error(res.message)
          }
        })

        // let result = await axios.get('/merge', {
        //   params: {
        //     hash: this.hash
        //   }
        // })
        // result = result.data
        // if (result.code == 0) {
        //   this.video = result.path
        // }
      }
      const send = async() => {
        // 已经中断则不再上传
        if (this.abort) return
        // if (i >= 5) {
        if (i >= requestList.length) {
          // 都传完了
          complete()
          return
        }
        let flg = true
        if (this.contrastarr.length > 0) {
          this.contrastarr.map(item => {
            // console.log('-----88888------')
            // console.log(i)
            // console.log(item)
            // console.log(this.partList)
            // console.log(this.partList[i])
            if (item.sliceNum === this.partList[i].sliceNum) {
              flg = false
            }
          })
        }
        if (flg) {
          await requestList[i]()
        }
        i++
        send()
      }
      send()
    },
    handleBtn() {
      if (this.btn) {
        // 断点续传
        this.abort = false
        this.btn = false
        this.sendRequest()
        return
      }
      // 暂停上传
      this.btn = true
      this.abort = true
    },
    test1(file) {
      if (file.event) {
        console.log('-------66')
        const size = file.file.originFileObj.size
        this.$http('post_largeFile_queryMain', { mainModel: 'GD_AJPC_DDXC', mainSize: size }, true).then(res => {
          if (res.code === 200) {
            if (res.message !== '已上传该文件') {
              this.changeFile(file)
              this.contrastarr = res.data
            } else {
              this.$message.error(res.message)
            }
          }
        })
        // this.changeFile(file)
      }
    },
    test2() {
      // var previewURL = 'localhost:8080/'
      window.open('/viewer.html?file=123.pdf')
      // window.open('../../assets/pdf/web/viewer.html?file=' + encodeURIComponent(previewURL + '/test?url=123.pdf'))
      // window.open('../web/viewer.html?file='+encodeURIComponent(previewURL+"/platservice/fileupload/getFileStream?fid="+fid));
    }
  }
}
</script>

<style lang="less" scoped>

</style>
